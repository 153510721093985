<template>
  <el-dialog
    title="项目详情"
    :visible.sync="detailShow"
    width="80%"
  >
    <template v-if="detailShow">
      <el-descriptions v-if="detailInfo.type === 1" class="margin-top" :column="3" border>
        <el-descriptions-item label="名称">{{ detailInfo.authContentOne }}</el-descriptions-item>
        <el-descriptions-item label="身份证号">{{ detailInfo.authContentTwo }}</el-descriptions-item>
        <el-descriptions-item label="身份证图片">
          <el-image style="width:400px;height:200px;" :src="detailInfo.authContentThree" :preview-src-list="[detailInfo.authContentThree]" />
          <el-image style="width:400px;height:200px;margin-left:20px;" :src="detailInfo.authContentFour" :preview-src-list="[detailInfo.authContentFour]" />
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions v-if="detailInfo.type === 2" class="margin-top" :column="3" border>
        <el-descriptions-item label="公司">{{ detailInfo.authContentOne }}</el-descriptions-item>
        <el-descriptions-item label="职位">{{ detailInfo.authContentTwo }}</el-descriptions-item>
        <el-descriptions-item label="证明图片">
          <el-image style="width:400px;height:200px;" :src="detailInfo.authContentThree" :preview-src-list="[detailInfo.authContentThree]" />
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions v-if="detailInfo.type === 3" class="margin-top" :column="3" border>
        <el-descriptions-item label="学校名称">{{ detailInfo.authContentOne }}</el-descriptions-item>
        <el-descriptions-item label="学历">{{ detailInfo.authContentTwo }}</el-descriptions-item>
        <el-descriptions-item label="证书图片">
          <el-image style="width:400px;height:200px;" :src="detailInfo.authContentThree" :preview-src-list="[detailInfo.authContentThree]" />
        </el-descriptions-item>
      </el-descriptions>
    </template>
  </el-dialog>
</template>

<script>
import form from '@/mixins/form'
import * as api from '@/api/check'
export default {
  mixins: [form],
  data() {
    return {
      api
    }
  }
}
</script>

<style>

</style>
