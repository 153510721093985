import request from '@/utils/axios'

/** 获取认证审核列表 */
export function getList(params) {
  return request({
    url: '/audit',
    method: 'get',
    params
  })
}

/** 审核失败 */
export function examineFail(id) {
  return request({
    url: `/audit/fail?id=${id}`,
    method: 'put'
  })
}

/** 审核成功 */
export function examineSuccess(id) {
  return request({
    url: `/audit/success?id=${id}`,
    method: 'put'
  })
}

/** 认证审核详情 */
export function getDetail(params) {
  return request({
    url: `/audit/detail`,
    method: 'get',
    params
  })
}
