<template>
  <el-dialog
    title="审核"
    :visible.sync="show"
    width="600px"
  >
    <el-form v-if="show" :model="form" label-width="auto">
      <el-form-item label="审核结果">
        <el-select v-model="form.type" placeholder="请选择审核结果" clearable style="width: 100%">
          <el-option v-for="(value, key) in typeList" :key="key + 1" :value="key + 1" :label="value" />
        </el-select>
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="end">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import form from '@/mixins/form'
import * as api from '@/api/check'

export default {
  mixins: [form],
  data() {
    return {
      api,
      typeList: ['审核通过', '审核不通过'],
      show: false,
      form: {}
    }
  },
  methods: {
    examineSuccess() {
      this.api.examineSuccess(this.id).then(() => {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.$parent.refresh()
      })
    },
    examineFail() {
      this.api.examineFail(this.id).then(() => {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.$parent.refresh()
      })
    },
    submit() {
      if (!this.form.type) {
        this.$message.error('请选择审核结果')
        return
      }
      if (this.form.type === 1) {
        this.examineSuccess()
        this.back()
      } else {
        this.examineFail()
        this.back()
      }
    }
  }
}
</script>
